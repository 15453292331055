<template>
  <div>
    <b-container align-v="center">
      <b-row align-h="center">
        <a :href="`https://bluuu.co/articles/category/th/${type}`">
          <h4
            class="text-center mt-4 mb-0"
            style="color: #005dff; font-weight: 700; background-color: white; padding: 4px"
          >
            {{ $t('pages.home.articles_header') }}
          </h4>
        </a>
        <slick
          v-if="articles.length > 0"
          class="slick-class testimonial d-flex justify-content-center"
          :options="slickTestimonialOptions2"
          style="width: 100%"
        >
          <div v-for="i in Array.from({ length: articles.length }, (_, i) => i)" :key="i">
            <div class="articles-card">
              <a :href="articles[i].post_url">
                <b-img
                  :src="
                    articles[i].thumbnail_url || require('../assets/images/maid_cleaning_new1.jpg')
                  "
                  class="card-img-top"
                  alt="Article Image"
                />
              </a>
              <div class="card-body">
                <a :href="articles[i].post_url">
                  <h5 class="card-title">
                    {{ articles[i].post_title }}
                  </h5>
                </a>
                <span>{{ articles[i].post_date | formatDate('DD MMMM YYYY') }}</span>
                <div class="card-text" v-html="articles[i].post_content"></div>
                <a :href="articles[i].post_url" class="read_more">
                  {{ $t('pages.home.read_more') }}
                </a>
              </div>
            </div>
          </div>
        </slick>
        <div style="width: 100%" v-else class="d-flex justify-content-center">
          <slick
            class="slick-class testimonial d-flex justify-content-center"
            :options="slickTestimonialOptions2"
            style="width: 100%"
          >
            <div v-for="i in [0, 1, 2, 3, 4]" :key="i">
              <div class="articles-card">
                <div style="height: 45%; background-color: #f0f0f0"></div>
              </div>
            </div>
          </slick>
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ApiClient from '../services'
import Slick from 'vue-slick'
export default {
  data() {
    return {
      slickTestimonialOptions2: {
        slidesToShow: 3,
        arrows: true,
        dots: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      articles: [],
    }
  },
  components: {
    Slick,
  },
  props: {
    type: {
      type: String,
      default: 'customer', //maid
    },
  },
  mounted() {
    this.getArticles()
  },
  methods: {
    async getArticles() {
      try {
        const result = await ApiClient.getArticles(this.type === 'customer' ? 1 : 3)
        this.articles = result.data ? result.data.slice(0, 10) : []
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
<style lang="scss" scoped>

.testimonial ::v-deep {
  .slick-dots:not(#_) {
    li button:before {
      background-color: #73757a;
    }
    li.slick-active button:before {
      border: none !important;
      background-color: #005dff !important;
    }
  }
  .slick-slide {
    margin: 20px;
    padding-top: 10px;
  }
  .slick-arrow::before {
    color: #005dff;
  }
}

.slick-class ::v-deep {
  .slick-prev,
  .slick-next {
    width: auto;
    height: auto;
  }
}

::v-deep .slick-arrow::before {
  font-size: 50px;
}

::v-deep .slick-prev {
  left: -70px;
}

::v-deep .slick-next {
  right: -70px;
}

.testimonial-card {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  position: relative;
}

.articles-card {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  height: 350px;
  overflow: hidden;
  img {
    height: 45%;
    object-fit: cover;
  }
  .card-title {
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  span {
    font-size: 14px;
    font-weight: normal;
  }

  .card-text {
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .read_more {
    text-decoration: underline;
    color: #005dff;
    font-size: 14px;
    font-weight: 500;
  }
}

.testimonial-textarea-div {
  border-radius: 14px;
  background-color: white;
  box-shadow: 0px -5px 10px 2px white;
  padding-top: auto;
  padding-bottom: auto;
  height: auto;
  position: relative;
}

.testimonial-textarea {
  padding: 20px;
  height: 300px;
}

.testimonial-img {
  position: absolute;
  left: 50%;
  top: 0%;
  width: 30%;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: white;
}

.testimonial-img-space {
  width: 30%;
  aspect-ratio: 2 / 1;
}

.testimonial-img img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  width: 100%;
  border-radius: 100%;
}

.testimonial-namearea {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.testimonial-double {
  position: absolute;
  width: 25px;
  aspect-ratio: 1.2 / 1;
}


@media screen and (max-width: 1199px) {
  ::v-deep .slick-prev {
    left: -50px;
  }

  ::v-deep .slick-next {
    right: -50px;
  }

  .testimonial-textarea {
    height: 320px;
  }

  .maid-slick ::v-deep {
    .slick-arrow::before {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 993px) {
  .testimonial-textarea {
    height: 390px;
  }
}

@media screen and (max-width: 767px) {
  .slick-class {
    width: 80% !important;
  }

  ::v-deep .slick-arrow::before {
    font-size: 30px !important;
  }

  ::v-deep .slick-prev {
    left: -30px;
  }

  ::v-deep .slick-next {
    right: -30px;
  }

  .testimonial-textarea {
    height: 340px;
  }
}
@media screen and (max-width: 540px) {
  .testimonial-textarea {
    height: 360px;
  }
}
@media screen and (max-width: 424px) {
  .testimonial-textarea {
    height: 430px;
  }
}
@media screen and (max-width: 350px) {
  .testimonial-textarea {
    height: 500px;
  }
}
</style>
